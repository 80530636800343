'use strict';
/**
 * The creation/edition category page
 * @param {Object} $scope - the local scope object
 * @param {Object} $state - the state object
 * @param {Object} DataManager - the DataManager API
 * @param {Object} Notification - The Notification util
 */
angular.module('cpformplastApp.clients')
  .controller('clientCreationController', function($scope, $state, DataManager, Notification) {

    $state.client = {
      terms: 'term_1'
    };

    $scope.submitted = false;
    $scope.salesmen = [];
    $scope.addresses = [];
    $scope.terms = ['term_1', 'term_2'];

    /**
     * Initialisation function
     * If we update, fetch the category with his id
     * otherwise we create a new one
     */
    $scope.init = function(){

      // Fetch salesmen
      DataManager.fetchSalesmen().then((data) => {
        $scope.salesmen = data;
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un probème est survenu lors du chargement des vendeurs');
      });

      if (!$state.params.clientId)
        return;
      DataManager.fetchClient($state.params.clientId)
        .then(client => $scope.client = client)
        .catch(error => console.log(error));
    };

    $scope.searchAddress = function(value) {
      DataManager.autocomplete(value)
      .then((data) => {
        $scope.addresses = data;
      })
    };

    $scope.saveSalesman = function(form, create) {

      if ($scope.client.salesman._id) {
        $scope.client.salesmanId = $scope.client.salesman._id;
        $scope.saveClient(form, create);
      } else {
        DataManager.createSalesman($scope.client.salesman)
        .then((data) => {
          $scope.client.salesmanId = data._id;
          $scope.saveClient(form, create);
        })
        .catch(err => {
          Notification.error('Un problème est survenu');
        });
      }

    };

    $scope.saveClient = function(form, create) {

      let promise = !$scope.client._id ? DataManager.createClient($scope.client):
                                         DataManager.updateClient($scope.client);
      //Success
      promise.then(data => {
        Notification.success(`Le client <strong> ${$scope.client.name}</strong> fut sauvegardé`);
        if (create) {
          $state.go('clients/client-management/client-creation',{'clientId' : ''}, {reload:true});
        } else {
          $state.go('clients/client-management/client-creation', {'clientId' : data._id}, {reload:true});
        }
      })
       //Failure
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu');
      });
    };

    /**
     * Validate the category submission
     * @param {Object} form - the category form
     * @param {Boolean} create - if 'true' go to a new category creation page
     * @param {Object} message - to customize the output message
     * @return {Boolean}
     */
    $scope.submitClient = function(form, create) {

      // //Initialisation
      $scope.submitted = true;
      if (!form.$valid){ return false; }

      $scope.saveSalesman();
    };

    //INIT
    $scope.init();
  });
